import { Button, ButtonGroup, Grid } from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ScheduleIcon from '@material-ui/icons/Schedule';

import './App.css';
import { BlogList } from './BlogList';

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <div className="wrap">
          <section className="profile">
            <div className="portrait">
              <a href="/">
                <div className="spinner" />
                <div
                  className="img"
                  style={{
                    backgroundImage: 'url("/Shams_Nahid.jpg")',
                  }}
                />
              </a>
            </div>
            <h1 className="name">
              Shams <b>Nahid</b>
            </h1>
            <h2 className="title">Senior Software Engineer</h2>
            <h4 style={{ paddingBottom: '16px' }}>
              Node.js | React | React Native | MongoDB | AWS
            </h4>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ paddingBottom: 16 }}
            >
              <Grid item>
                <div style={{ display: 'flex' }}>
                  <PhoneIcon style={{ fontSize: 18, paddingRight: 4 }} />
                  +353 085 1879930
                </div>
              </Grid>
              <Grid item>
                <div style={{ display: 'flex' }}>
                  <EmailIcon style={{ fontSize: 18, paddingRight: 4 }} />
                  b.m.shamsnahid@gmail.com
                </div>
              </Grid>
              <Grid item>
                <a style={{ display: 'flex', color: 'black', textDecoration: 'underline' }} href='https://calendly.com/shams-nahid' target='_blank' rel="noreferrer">
                  <ScheduleIcon style={{ fontSize: 18, paddingRight: 4 }} />
                  Schedule A Call
                </a>
              </Grid>
            </Grid>
            <span className="bio">
              I am a disciplined Software Engineer with 6+ years of experience. I am proficient in using JavaScript frameworks and working with AWS Cloud Infrastructure (2x AWS Associate Level Certifications). Over the past 5 years, I have successfully collaborated with remote technical teams and clients from the USA and Singapore. In addition to web technologies, I am also actively expanding my skills in Serverless and DevOps. My professional goal is to continue growing and working in the field of Engineering and Software Architecture.
            </span>
          </section>
        </div>
        <div style={{ padding: '22px' }}>
          <Grid container justify="center" spacing={1}>
            {/* <Grid item>
              <Button
                size="small"
                variant="outlined"
                href="http://portfolio.shams-nahid.com/"
                target="_blank"
              >
                Portfolio
              </Button>
            </Grid> */}
            <Grid item>
              <ButtonGroup aria-label="outlined secondary button group">
                <Button
                  size="small"
                  href="http://cv.shams-nahid.com/"
                  target="_blank"
                  disabled
                >
                  View Resume
                </Button>
                <Button
                  size="small"
                  href="https://s3.ap-south-1.amazonaws.com/cv.shams-nahid.com/B_M_Shams_Nahid_CV.pdf"
                  target="_blank"
                >
                  Download CV
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item>
              <ButtonGroup aria-label="outlined secondary button group">
                <Button
                  size="small"
                  href="https://blog.shams-nahid.com/"
                  target="_blank"
                >
                  Personal Blog
                </Button>
                <Button
                  size="small"
                  href="https://ixorasolution.com/author/sn/"
                  target="_blank"
                >
                  Office Blog
                </Button>
                <Button
                  size="small"
                  href="https://shams-nahid.medium.com/"
                  target="_blank"
                >
                  Medium
                </Button>
                {/* <Button size="small" 
                  href="https://epitaph.shams-nahid.com/"
                  target="_blank">
                  এপিটাফ
                </Button>
                <Button size="small" 
                  href="https://twitter.com/_shams_nahid"
                  target="_blank">
                  <TwitterIcon />
                </Button> */}
              </ButtonGroup>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container justify="center" spacing={1}>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                startIcon={<GitHubIcon />}
                href="https://github.com/shams-nahid"
                target="_blank"
              >
                Github
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                startIcon={<LinkedInIcon />}
                href="https://www.linkedin.com/in/shams-nahid/"
                target="_blank"
              >
                LinkedIn
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                href="https://stackoverflow.com/users/5361646/shams-nahid"
                target="_blank"
              >
                Stack Overflow
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                href="https://leetcode.com/u/shams-nahid/"
                target="_blank"
              >
                LeetCode
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                href="https://www.npmjs.com/~bmshamsnahid"
                target="_blank"
              >
                npm
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                href="https://www.freecodecamp.org/bmshamsnahid"
                target="_blank"
              >
                Free Code Camp
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                href="https://www.strava.com/athletes/126358041"
                target="_blank"
              >
                Strava
              </Button>
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item><BlogList /></Grid>
        </div>
        <div style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '12px'
        }}>
          <Grid item >My Latest Run Stats...</Grid>
          <iframe height='454' frameborder='0' allowtransparency='true' scrolling='no' src='https://www.strava.com/athletes/126358041/latest-rides/8f8366eb73307dfb6ca15a6cd57df614f1eb4a21'></iframe>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
